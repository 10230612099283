@font-face {
  font-family: "Figtree";
  src: url("/fonts/Figtree-Regular.woff2") format("woff2"),
    url("/fonts/Figtree-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Figtree";
  src: url("/fonts/Figtree-Light.woff2") format("woff2"),
    url("/fonts/Figtree-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Figtree";
  src: url("/fonts/Figtree-Bold.woff2") format("woff2"),
    url("/fonts/Figtree-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Figtree";
  src: url("/fonts/Figtree-Italic.woff2") format("woff2"),
    url("/fonts/Figtree-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
