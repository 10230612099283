@charset 'utf-8';

@import "~reset-css/sass/reset";

@import "nprogress";
@import "fonts";

html {
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #fff;
  font-size: 1.6rem;
  line-height: 1.428;
  color: #363334;
  font-family: "Figtree", -apple-system, BlinkMacSystemFont, Helvetica,
    sans-serif;
}

* {
  font: inherit;
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

img {
  display: block;
  width: auto;
  max-width: 100%;
}

svg {
  display: block;
}

strong,
b {
  font-weight: 700;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1rem;
  line-height: 1.1;
  font-weight: 700;
}

button {
  appearance: none;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  margin: 0;

  &:hover,
  &:focus {
    cursor: pointer;
  }
}
